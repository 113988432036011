 
<template> 
    <div style="padding: .2rem; background: #fff;">
        <div style="padding: 0.3rem; text-align: center; font-size: 0.5rem;">{{title}}</div>
        <div v-html="content" style="padding-top: 0.2rem;font-size: 0.3rem;"></div>
    </div>
 
</template>
 
<script>  

import { getMessageDetail } from '@/api/messageApi'
    export default {
        name: 'detail',  
        data() {
            return {
                title: '',
                content: '',
                id: '',
            }
        },
        computed: {},
        watch: {},
        created() {
            this.id = this.$route.query.id
            this.getDetail()
        }, 
        mounted() {},
        beforeCreate() {},
        beforeMount() {},
        beforeUpdate() {},
        updated() {},
        beforeDestroy() {},
        destroyed() {},
        activated() {},
        methods: {
            getDetail() {  
                let data = { id: this.id }
                getMessageDetail(data).then(res => { 
                    this.title = res.data.title
                    this.content = res.data.content
                    // this.currentList = res.data.records 
                    // this.list = this.list.concat(res.data.records) 
                    // this.loading = false
                })
            },
        }
    }
</script>
 
<style lang='scss' scoped> 
    // .main_box {
    //     height: 100%;
    //     padding: 0.2rem 0;
    // }
    // .van-hairline--surround {
    //     background-color: #fff;
    // }
</style>
